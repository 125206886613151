import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { ApolloProvider } from "@apollo/client";

import Login, { logout } from "./login/Login";
import { buildApolloClient, LoadingStatus } from "./common/Network";
import styles from "./App.module.css";

const Home = lazy(() => import("./home/Home"));
const BrowseApp = lazy(() => import("./browse/Browse"));
const EcologyApp = lazy(() => import("./ecology/Ecology"));
const ToxicologyApp = lazy(() => import("./toxicology/Toxicology"));
const ScreenApp = lazy(() => import("./screen/Screen"));
const AdminApp = lazy(() => import("./admin/Admin"));

export default function App() {
  return (
    <ApolloProvider client={buildApolloClient()}>
      <Router>
        <AppHeader />
        <Container className={styles.appContent} fluid>
          <Suspense fallback={<LoadingStatus />}>
            <Login>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/browse" component={BrowseApp} />
                <Route path="/screen" component={ScreenApp} />
                <Route path="/ecology" component={EcologyApp} />
                <Route path="/toxicology" component={ToxicologyApp} />
                <Route path="/admin" component={AdminApp} />
              </Switch>
            </Login>
          </Suspense>
        </Container>
      </Router>
    </ApolloProvider>
  );
}

function AppHeader() {
  return (
    <header className={styles.appHeader}>
      <h4>
        化学品风险管控关键技术集成应用平台
        <br />
        <small>
          Integrated application platform for chemical risk management and
          control
        </small>
      </h4>
      <ul className={styles.appHeaderActions}>
        {/* TOOD: more actions here */}
        <li>
          <Switch>
            <Route exact path="/">
              <a href="#" onClick={(e) => logout()} role="button">
                退出登录
              </a>
            </Route>
            <Route>
              <Link to="/">返回主页</Link>
            </Route>
          </Switch>
        </li>
      </ul>
    </header>
  );
}
